.hero {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: var(--space-lg);
}

.hero h1 {
    font-weight: var(--font-weight-heavier) !important;
}

.hero h3 {
    font-weight: var(--font-weight-light);
}

.Information {
    overflow: auto;
}

.Information .chapter {
    padding: var(--space-md);
}

.FeatureSite {
    display: grid;
    grid-gap: var(--space-md);
}

.feature-list {
    display: grid;
    grid-gap: var(--space-lg);
}

@media (min-width: 992px) {
    .feature-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 992px) {
    .feature-list {
        grid-template-columns: 1fr;
    }
}

.indented.section {
  margin-left: var(--space-sm);
}

.feature {
    --primary-color: var(--terra-cotta);

    color: var(--primary-color);
    background-color: #fff;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: var(--space-md);
}

.feature .icon {
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    width: 50px;
    height: 50px;
    box-shadow: var(--default-shadow);
}

.feature .details {
    display: grid;
    grid-gap: var(--space-xs);
}

.feature .headline {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: start;
}

.feature .name {
    font-size: var(--text-size-5);
    font-weight: var(--font-weight-heavier);
}

.feature .description {
    font-size: var(--text-size-2);
    font-weight: var(--font-weight-light);
}

.tag {
    background-color: var(--terra-cotta);
    color: #fff;
    padding: var(--space-xss) var(--space-xs);
    display: grid;
    align-items: center;
    font-weight: var(--font-weight-light)
}

.text-wrapper {
    max-width: 860px;
}

.avatar {
    display: grid;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #fff;
    background-image: url("/Images/avatar.jpg");
    background-size: cover;
    box-shadow: var(--inset-shadow);
}

.whoami .content {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: var(--space-md);
}

.FeatureSite .section {
    display: grid;
    padding: 0 var(--space-md);
}

.FeatureSite .section h1 {
    color: var(--terra-cotta);
    margin: 0 0 var(--space-md) 0;
}

.showcase {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: var(--space-md);
}

.showcase .details {
    display: grid;
    width: fit-content;
}

.showcase .details button {
    justify-self: flex-end;
}
