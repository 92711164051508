.Diagram > .body {
	margin-top: 200px;
	position: relative;
}

.Diagram > .header .buttons {
    float: right;
    display: flex;
    flex-direction: row;
}

.Diagram .header {
    display: flex;
}

