
.Thumbnail {
    border: #FFB6C1 1px solid;
    background-color: #EEEEEE;
    border-radius: 10px;
    margin: 0px 0px 5px 5px;
    cursor: pointer;
}

.Thumbnail > .SingleLineEditor {
    border-bottom: #555555 solid 1px;
}

.Thumbnail > .picture{
    height: 200px;
    width: 200px;
}

.Thumbnail > .picture > .fakePicture{
    position: relative;
    top: 70px;
    rotate: 45deg;
    font-size: xx-large;
    color: cadetblue;
}