
.View {
    background-color: white;
	border-color: var(--nadeshiko-pink);
}


.View > .header {
    border-bottom: 1px solid #FFB6C1;
    color: white;
	background-color: var(--nadeshiko-pink);
}
