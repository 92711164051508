


.ReorderableMember{
    position: relative;
}
.ReorderableMember > .reorderControls {
    display: flex;
    position: absolute;    
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 1000;
    opacity: 0;
}



.ReorderableMember .SingleLineEditor .LazyInput.InActive{
    position: absolute;
    right: 25px;
    opacity: 0;
}

.ReorderableMember .SingleLineEditor .Element.Cancel{
    position: absolute;
    right: 5px;
    opacity: 0;
}

.ReorderableMember .LazyInput.InActive{
    position: absolute;
    right: 25px;
}

.ReorderableMember:hover > .reorderControls {
    opacity: 1;
}


.ReorderableMember:hover .SingleLineEditor .Element.Cancel{    
    opacity: 1;
}

.ReorderableMember:hover .SingleLineEditor .LazyInput.InActive{
    opacity: 1;
}