.Element{
    cursor: pointer;
     z-index: 1000;
}

.Element.AddCircle{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border-radius: 50%;
}
