
.Button{
    background-color: var(--primary-color);
    font-size: var(--text-size-2);
    cursor: pointer;
    padding: var(--space-sm) var(--space-md);
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    width: 200px;
    text-align: center;
    margin: auto;
    font-size: larger;
    cursor: pointer;
    z-index: 1000;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
}
