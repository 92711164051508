
.DragableItem  {
    min-width: 100px;
    border-width: 1.3px;
    border-style: solid;
    width: fit-content;
    position: absolute;
    z-index: 100;
    box-shadow: var(--default-shadow);
}


.DragableItem > .LazyInput.InActive { 
    background-color: var(--black-coffee);
	display: flex;
	padding: 3px;
	font-size: var(--text-size-6);
	align-items: center;
	justify-content: center;

}
.DragableItem > .LazyInput.InActive .Element.AddCircle{ 
	width: 25px;
	height: 25px;
	background-color: var(--primary-color);
	color: white;
}

.DragableItem > .LazyInput.InActive:hover {    
    background-color: var(--nadeshiko-pink);
}

.DragableItem > .header.grab {
    cursor: grab;
}

.DragableItem > .header.grabbing {
    cursor: grabbing;
}

.DragableItem > .header .SingleLineEditor .LazyInput.InActive{
    position: absolute;
    right: 25px;
    opacity: 0;
    top: 0px;
}
.DragableItem > .header .SingleLineEditor .Element.Cancel{
    position: absolute;
    right: 5px;
    top:0px;
    opacity: 0;
}

.DragableItem > .header {
    height: 30px;
    position: relative;
    width: 100%;
}
.DragableItem  .section {
    margin: 4px 2px 4px 2px;
}

.DragableItem .ReorderableMember .SingleLineEditor .title {
    width: 100%;
}
.DragableItem  > .header .title {
    display: flex;
    margin-top: 1px;
    margin-left: 3px;
	white-space: nowrap;
}

.DragableItem > .header:hover > .reorderControls {
    opacity: 1;
}


.DragableItem > .header:hover .SingleLineEditor .Element.Cancel{    
    opacity: 1;
}

.DragableItem > .header:hover .SingleLineEditor .LazyInput.InActive{
    opacity: 1;
}

.DragableItem  .section .Element.Arrow{
    color: black;
}
