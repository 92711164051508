
.TabView .header{
	display: flex;
	font-size: var(--text-size-5);
	width: min-content;
	background-color: var(--primary-color);
	padding: var(--space-xss) var(--space-xss) 0px 0px;
	border-radius: var(--space-xss) var(--space-xss) 0px 0px;
}

.TabView .headElement {
    color: var(--primary-color);
    background-color: var(--background-color);
    font-weight: var(--font-weight-heavier);
    padding: var(--space-xs);
    cursor: pointer;
    margin-left: var(--space-xss) ;
    z-index: 1;
}

.TabView .headElement:hover,
.TabView .headElement.chosen {
	background-color: var(--hover-color);
}

.TabView > .body {
	position: relative;
	top: -0.25rem;
	border: var(--space-xss) solid var(--primary-color);
	color:var(--primary-color);
    background-color: var(--background-color);
}
