.Numl {
    border-color: var(--primary-color);
    background-color: white;
}



.DragableItem > .header .title {
	white-space: nowrap;
}
.Numl > .header {
	display: flex;
    color: white;
    background-color: var(--primary-color);
}



.Numl .divider {
    border-bottom: 1px var(--primary-color);
    border-bottom-style: dashed;
    height: 1px;
}

.Numl .Property {
    width: 100%;
}
