
.LazyInput{
    position: relative;
    z-index: 1000;
}



.LazyInput.Active {
    display: flex;
    flex-direction: row;
}

.LazyInput.InActive {
    cursor: pointer;
}

.LazyInput.Active .exit {
    background-color: red;
    color: white;
}

