.Documentation {
    	font-size: var(--text-size-2);
}

.Documentation > .section {
    box-shadow: var(--default-shadow);
    margin: var(--space-xs);
    padding: var(--space-xs);
}


.Documentation > .section > .header {
	/* text-decoration: underline var(--nadeshiko-pink); */
	color: var(--nadeshiko-pink);
}

.Documentation .article > .header{
	text-decoration: underline var(--nadeshiko-pink);
}

.Documentation .article > .body {
	display: flex;
	flex-wrap: wrap;
}

.Documentation .article .paragraphs {
	width: 700px;
}

.Documentation .example {
	width: 500px;
	height: 250px;
}

.Documentation .TabView {
	width: 1000px;
}

/*
.Documentation .article {
	border-bottom: 0.5px solid var(--desert-sand);
	border-right: 2px solid var(--desert-sand);
	padding-left: 5px;
	padding-bottom: 15px;
	padding-right: 15px;
}*/
