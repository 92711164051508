.Page.WithMenu{
  display: grid;
  grid-template-columns: auto 1fr;
}

.Page {
  height: 100vh;
}

.Page.WithMenu .mainContent{
    display: grid;
    grid-gap: var(--space-lg);
}

.MasterPage > .header  > .left > .Button{
    width: 100px;
    height: 40px;
    color: #555555;
    background-image: linear-gradient(to bottom right, #EBEBEB , #F2F2F2);
    border-radius: 0px 0px 50px 0px;
    border: #FFB6C1 solid;
    border-top-width: 0px;
    border-left: 0px;
    border-right: 1px;
    border-bottom-width: 1.75px;
}


.MasterPage > .header  > .left > .Button:hover{
    background-image: linear-gradient(to bottom right, #F2F2F2 , #EBEBEB);
}

.MasterPage .Menu.header{
    color: var(--nadeshiko-pink);
}

.MasterPage .Menu.header  .material-icons{
    color: var(--terra-cotta);
    font-size: var(--text-size-10);
}

.queer{
    display: flex;
}

.queer > div{
    flex-direction: row;
}

.MasterPage .Menu.header .right{
    position: absolute;
    font-size: var(--text-size-10);
    right: 50px;
    background-color: var(--black-coffee);
    color: var(--terra-cotta);
    width: 200px;
    text-align: center;
    margin: auto;
    font-size: larger;
    cursor: pointer;
    z-index: 1000;
    vertical-align: middle;
    line-height: 30px;
}

