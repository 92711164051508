.Menu {
  --padding: var(--space-md);

  background-color: var(--black-coffee);
  height: calc(100% - 2 * var(--padding));
  padding: var(--padding);
  box-shadow: var(--default-shadow);
}

.Menu .Button {
  text-align: left;
  padding: var(--space-xs)
}



.Menu .Button{
    /*background-image: linear-gradient(to bottom right, #FFBFCA , #FFB6C1);*/
    background-color: var(--black-coffee);
    color: var(--terra-cotta);
    position: relative;
    width: 200px;
    text-align: center;
    margin: auto;
    font-size: larger;
    cursor: pointer;
    z-index: 1000;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
}

.Menu .Button:hover{
    background-color: var(--nadeshiko-pink);
}
