* {
    --text-size-1:  12px;
    --text-size-2:  14px;
    --text-size-3:  16px;
    --text-size-4:  18px;
    --text-size-5:  20px;
    --text-size-6:  24px;
    --text-size-7:  30px;
    --text-size-8:  36px;
    --text-size-9:  48px;
    --text-size-10: 60px;
    --text-size-11: 72px;

    --font-weight-light:   200;
    --font-weight-normal:  400;
    --font-weight-heavier: 600;
}

body {
  font-size: var(--text-size-2);
  font-weight: var(--font-weight-normal);
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: var(--font-weight-light) !important;
}

h1 {
  font-size: var(--text-size-7);
}

h2 {
  font-size: var(--text-size-5)
}
