


.Header{
    display: flex;
    width: 100%;
}


.Header > .center{
    width: 100%;
}