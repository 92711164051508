* {
	--terra-cotta: hsla(8, 70%, 62%, 1);
	--nadeshiko-pink: hsla(346, 69%, 80%, 1);
	--desert-sand: hsla(24, 37%, 75%, 1);
	--khaki-web: hsla(39, 19%, 67%, 1);
	--black-coffee: hsla(327, 19%, 20%, 1);

	--primary-color: var(--terra-cotta);
	--background-color: var(--black-coffee);
	--hover-color: var(--nadeshiko-pink);
	--text-color: var(--black-coffee);
	--type-color: var(--khaki-web);
}

body {
	color: var(--text-color);
}

.terra-cotta {
	--primary-color: var(--terra-cotta);
	--secondary-color: white;
}

.nadeshiko-pink {
	--primary-color: var(--nadeshiko-pink);
	--secondary-color: white;
}

.khaki-web {
	--primary-color: var(--khaki-web);
	--secondary-color: white;
}

.desert-sand {
	--primary-color: var(--desert-sand);
	--secondary-color: white;
}

.black-coffee {
	--primary-color: var(--black-coffee);
	--secondary-color: white;
}

.white {
	--primary-color: white;
	--secondary-color: var(--black-coffee);
}

button {
	font-weight: var(--font-weight-normal);
	padding: var(--space-xs) var(--space-md);
}

button.primary {
	background-color: var(--primary-color);
	color: var(--secondary-color);
	border: 0px;
}

button.secondary {
	background-color: white;
	color: var(--primary-color);
	border: 1px solid var(--primary-color);
}

button.tertiary {
	background-color: white;
	color: var(--primary-color);
	border: 0px;
}
