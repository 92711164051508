


.FileView .ebaexY {
	border-radius: 0%;
}

.FileView .fileName{
	padding: 2px;
	padding-left: var(--space-xs);
}
