
.queer{
    display: flex;
    flex-direction: row;
}

.Property {
    display: flex;
    flex-direction: row;
}
