.Choice.Button{
    position: relative;
}

.ChoiceButtonList div{
    z-index: 2501;
}
.ChoiceButtonList{
    position: absolute;
    border: #FFB6C1 solid 1px;
    width: 100%;
    text-align: left;
    z-index: 1500;
    background-color: #EEEEEE;
}


.ChoiceButtonList > div:hover{
    cursor: pointer;
    background-color: #FFB6C1;
}
