
.Diagrams > .Element{
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: var(--text-size-2);
  cursor: pointer;
  padding: var(--space-sm) var(--space-sm);
  color: white;
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 50%;
  background-color: var(--primary-color);
  box-shadow: var(--default-shadow);
} 

.Diagrams  .SingleLineEditor .Button{   
    width: 400px;
    margin: 3px;
    line-height: 100px;
    color: #555555;
    background-image: linear-gradient(to bottom right, #EBEBEB , #F2F2F2);
    border-radius: 50px 0px 50px 0px;
    border: #FFB6C1 solid;
    border-top-width: 1px;
    border-left: 3px;
    border-right: 1px;
    border-bottom-width: 3px;
}

/*.Diagrams .addDiagram {
  background-color: #FFB6C1;
  position: fixed;
  right: 50px;
  top: 20px;
  color: #555;
}*/


.Diagrams .diagrams {
  min-height: 500px;
  min-width: 500px;
  padding-right: 90px;
}
