

.SingleLineEditor {
    z-index: 1000;
}

.SingleLineEditor > *{
    display: inline-block;
}

.SingleLineEditor .Element.Cancel {
    cursor: pointer;
}

