


.Error {
    position: relative;
    z-index: 2000;
    border-radius: 15px;
    border-width: 2px;
    border-style:  solid;
    border-color:  orangered;
    background-color: orange;
    font-size: 18px;
    padding: 5px;
    color: orangered;
}

.Error > div:nth-child(2){
    border-radius: 15px;    
    border-width: 1px;
    border-style:  solid;
    border-color:  orangered;
    padding: 5px;
}