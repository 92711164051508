
.Enum {
	border-color: var(--khaki-web);
    background-color: white;
}

.Enum > .header {
	color: var(--khaki-web);
	background-color: white;
	border-bottom: 1px solid var(--khaki-web);
}

.Enum .section {
	/*color: var(--khaki-web);*/
}




