.fakethumbnail{
    font-size: var(--text-size-11);
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: var(--black-coffee);
    color: var(--terra-cotta);
    display: grid;
    align-items: center;
    justify-items: center;
}

.fakethumbnail .overlay {
    display: grid;
    text-align: center;
}

.fakethumbnail .overlay .play-button {
}

.fakethumbnail .overlay .play-button .material-icons {
    font-size: var(--text-size-10);
}
