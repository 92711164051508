

.Arrow.LeftTop{
    position: absolute;
    border-left: black 2px solid;
    border-top: black 2px solid;
}


.Arrow.RightTop{
    position: absolute;
    border: black 2px 2px 0px 0px solid;
}


.Arrow.LeftBottom{
    position: absolute;
    border-left: black 2px solid;
    border-bottom: black 2px solid;
}


.Arrow.RightBottom{
    position: absolute;
    border-bottom: black 2px solid;
    border-right: black 2px solid;
}
