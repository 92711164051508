.body{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
}

.Element {
    display: relative;
    z-index: 1000;
}
.icons{
    vertical-align: middle;
}

.Layers {
    position: relative;
}
.Layers .corner {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: var(--desert-sand);
    z-index: 1200;
    width: 40px;
    height: 40px;
}

.HorizontalLayer > .header {
    writing-mode: vertical-rl;
    width: 0px;
}


.HorizontalLayers .Button {
    width: 100%;
}

.VerticalLayers .Button {
    top:0px;
    height: 100%;
    width: 40px;    
}

.HorizontalLayers {
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
}



.VerticalLayers {
    position: absolute;
    display: flex;
    top:0px;
    left:0px;
    white-space: nowrap;
	flex-wrap: nowrap;
}
.VerticalLayer {
    float: left;
    height: 100%;
}


/* Body */
/*********************************************************************/


.HorizontalLayer{
    clear: both;
    position: relative;
    display: flex;
    flex-direction: row;
}

.HorizontalLayer > .body{
    position: relative;
    height: 100%;
    width: 100%;
}


.VerticalLayer > .body{
    height: 100%;
}
.VerticalLayer:nth-child(even) > .body {
    background-color: #55555520;
}
.HorizontalLayer:nth-child(even) > .body {
    background-color: #55555520;
}



/* Header */
/*********************************************************************/
.Layer .header .Element.Arrow {
  width: min-content;
  height: min-content;
  font-size: 25px;
  color: var(--black-coffee);
}

.Layer .header .Element.Arrow:hover {
	color:white;
}
.VerticalLayer .header > div {
	padding-left: var(--space-xs);
	padding-right: var(--space-xs);
}

.HorizontalLayer .header > div {
	padding-top: var(--space-xs);
	padding-bottom: var(--space-xs);
}

.VerticalLayer .header {
    width: 100%;
    height: 40px;
}

.HorizontalLayer .header {
    position: relative;
    width: 40px;
    z-index: 1000;
}

.Layer .header > div{
	margin-top: auto;
	margin-bottom: auto;
}
.Layer .header .Arrow{
    width: 30px;
    height: 30px;
    z-index: 1000;
}

.HorizontalLayer .header .center {
    height: 100%;
}
.Layer > .header {
    background-color: var(--desert-sand);
}

.Layer > .header .SingleLineEditor .LazyInput.InActive{

    opacity: 0;
}
.Layer > .header:hover .SingleLineEditor .LazyInput.InActive{

    opacity: 1;
}

.Layer > .header .SingleLineEditor .Element.Cancel{

    opacity: 0;
}



.Layer > .header:hover .SingleLineEditor .Element.Cancel{    
    opacity: 1;
}



/* ResizeLine */
/*********************************************************************/
.Layer > .body > .resizeLine{
    background-color: var(--desert-sand);
    --thickness: 4px;
    --hover-thickness: 6px;
}
.VerticalLayer > .body > .resizeLine{
    float: right;
    width: var(--thickness);
    bottom: 0px;
    height: 100%;
    position: relative;
    cursor: w-resize;
}

.VerticalLayer .resizeLine:hover{
    width: var(--hover-thickness)
}

.HorizontalLayer .header .SingleLineEditor .LazyInput.Active {
    position: relative;
    left:40px;
}

.HorizontalLayer > .body > .resizeLine{
    height: var(--thickness);
    width: 100%;
    position: absolute;
    bottom: 0px;
    cursor: s-resize;
}
.HorizontalLayer .resizeLine:hover{
    height: 4px;
}

.Layers > .LayersButton > .Button{
  background-color: var;
    color: #EEEEEE;
    background-color: var(--black-coffee);
}

.Layers  .LayersButton  .Button{
	display: flex;
  align-items: center;
  justify-items: center;
	justify-content: center;
	align-content: center;
    color: #EEEEEE;
    background-color: var(--black-coffee);
}

.Layers  .LayersButton  .Button .Element.AddCircle{
	height: 30px;
	width: 30px;
    background-color: var(--primary-color);
}


.Layers  .LayersButton  .Button:hover{
    background-color: var(--nadeshiko-pink);
}


.Layer .resizeLine{
    position: relative;
    z-index: 900;
}
